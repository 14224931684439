import React from "react"
// import 'bootstrap'
// import 'bootstrap/dist/css/bootstrap.min.css';
import "../styles/my-boostrap.scss"
import "../styles/layout.scss"
import "../styles/global.scss"
import Header from "../components/header"
import Footer from "../components/footer"

export default ({ children, pageId }) => (
  <>
    <Header current={pageId}/>
    <div style={{height: 56}}></div>
    {children}
    <Footer/>
  </>
)
