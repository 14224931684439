import React from "react";
import Layout from "../components/layout"
import "./index.scss"

export default () => (
  <Layout pageId={"/park"}>
    <div className="container-fluid bg-wave-top">
      <div className="container py-5">
        <div className="row align-items-center justify-content-center">
          <div className="col-12 col-md-6">
            <h1 style={{color:'white', fontWeight:'bold', fontStyle:'italic'}}>企业园区</h1>
            <p style={{color: 'white'}}>
              企业园区包括新兴的产业园、孵化器，以及“传统”的写字楼。企业园区由运营方、入驻企业、员工三方主体组成。典型的企业园区运营方组织架构包括综合部、招商部、运营部、物业部、财务部等。
            </p>
          </div>
          <div className="col-12 col-md-6">
            <img src="../images/chahua/2.png" className="img-fluid" alt=""/>
          </div>
        </div>
      </div>
    </div>
    <div className="container py-5">
      <div className="row justify-content-center align-items-center py-md-5">
        <div className="col-12 col-md-6">
          <h1>
            招商管理
          </h1>
          <p>
            客户信息登记、意向客户管理、商机管理、线索管理
            {/*无论是几个人运营的孵化器还是上百人管理运营的大型企业园区，企业内部的信息化管理是基础。你可以选择互联网企业协同工具，比如钉钉、企业微信、飞书，也可以选择更为传统、功能也更强大的传统OA软件，比如泛微、致远等。我们可以提供与这些协同平台的对接集成。*/}
          </p>
        </div>
        <div className="col-12 col-md-6">
          <img src="../images/chahua/3.png" className="img-fluid" alt=""/>
        </div>
      </div>
    </div>
    <div className="container-fluid bg-grey py-5">
      <div className="container py-md-5">
        <div className="row justify-content-center align-items-center flex-md-row-reverse">
          <div className="col-12 col-md-6">
            <h1>
              租赁管理
            </h1>
            <p>
              企业入驻、续租、退租等全流程电子化管理
            </p>
          </div>
          <div className="col-12 col-md-6">
            <img src="../images/chahua/6.png" className="img-fluid" alt=""/>
          </div>
        </div>
      </div>
    </div>
    <div className="container py-5">
      <div className="row justify-content-center align-items-center py-md-5">
        <div className="col-12 col-md-6">
          <h1>
            空间运营
          </h1>
          <p>
            活动管理、贴吧论坛、资讯发布，以及会议室预定等增强空间与企业的互动。
          </p>
        </div>
        <div className="col-12 col-md-6">
          <img src="../images/chahua/2_1.png" className="img-fluid" alt=""/>
        </div>
      </div>
    </div>
    <div className="container-fluid bg-grey py-5">
      <div className="container py-md-5">
        <div className="row justify-content-center align-items-center flex-md-row-reverse">
          <div className="col-12 col-md-6">
            <h1>
              品质服务
            </h1>
            <p>
              面向入驻企业与人员的服务流程管理。可以灵活配置信息表单、服务流程、处理审批流程。服务处理进展、处理率等一目了然。
            </p>
          </div>
          <div className="col-12 col-md-6">
            <img src="../images/chahua/1.png" className="img-fluid" alt=""/>
          </div>
        </div>
      </div>
    </div>
    <div className="container py-5">
      <div className="row justify-content-center align-items-center py-md-5">
        <div className="col-12 col-md-6">
          <h1>
            物业管理
          </h1>
          <p>
            涵盖报修、通行、访客、巡更巡检等物业基础服务管理
          </p>
        </div>
        <div className="col-12 col-md-6">
          <img src="../images/community/2.png" className="img-fluid" alt=""/>
        </div>
      </div>
    </div>
  </Layout>
);
