import React from "react"
// import AniLink from "gatsby-plugin-transition-link/AniLink"
import { Link } from "gatsby"
import gsap from "gsap"
import Layout from "./layout"

// import '../pages/index.css'

class Header extends React.Component {

    componentDidMount() {
        // gsap.to(".navbar", {duration: 2, rotationX: 360})
        // gsap.to(".navbar-brand", {duration: 1, x: 50, ease: "bounce"})
    }

    render() {
        return (
            <header className="fixed-top shadow-sm bg-white">
                <nav className="container navbar navbar-expand-md navbar-light">
                    {/*<a className="navbar-brand" href="/">果心科技 <span style={{fontSize: "0.6em"}}>京股代码100159</span></a>*/}
                    <a className="navbar-brand" href="/">
                        果心科技
                        {/*<img src="../images/logo-big-blue.png" style={{height: 25}}/>*/}
                        {/*<img src="../images/logo2.png" style={{height: 25}} />*/}
                    </a>
                    <button className="navbar-toggler" type="button" data-toggle="collapse"
                            data-target="#navbarSupportedContent"
                            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"/>
                    </button>
                    <div className="collapse navbar-collapse mt-2 mt-md-0" id="navbarSupportedContent">
                        <ul className="navbar-nav ml-auto">
                            <li className={this.props.current === "/" ? "nav-item active" : "nav-item"}>
                                <Link className="nav-link" to="/">
                                    首页
                                </Link>
                            </li>
                            <li className={this.props.current === "/park" ? "nav-item active" : "nav-item"}>
                                <Link className="nav-link" to="/park">
                                    园区
                                </Link>
                            </li>
                            <li className={this.props.current === "/community" ? "nav-item active" : "nav-item"}>
                                <Link className="nav-link" to="/community">
                                    社区
                                </Link>
                            </li>
                            {/*<li className={this.props.current === "/recruit" ? "nav-item active" : "nav-item"}>*/}
                            {/*    <Link className="nav-link" to="/recruit">*/}
                            {/*        人才服务*/}
                            {/*    </Link>*/}
                            {/*</li>*/}
                            {/*
              <li className={this.props.current === '/ren-lian-shi-bie' ? "nav-item active" : 'nav-item'}>
                <Link className="nav-link" to="/ren-lian-shi-bie">
                  人脸识别
                </Link>
              </li>
              <li className={this.props.current === '/shou-ji-kai-men' ? "nav-item active" : 'nav-item'}>
                <Link className="nav-link" to="/shou-ji-kai-men">
                  手机开门
                </Link>
              </li>
*/}

                            {/*
              <li className={this.props.current === '/iot' ? "nav-item active" : 'nav-item'}>
                <AniLink className="nav-link" to="iot">
                  物联
                </AniLink>
              </li>
*/}
                            <li className={this.props.current === "/product" ? "nav-item active" : "nav-item"}>
                                <Link className="nav-link" to="/product">
                                    产品
                                </Link>
                            </li>
                            {/*
              <li className={this.props.current === '/zhi-hui-tong-xing' ? "nav-item active" : 'nav-item'}>
                <Link className="nav-link" to="/zhi-hui-tong-xing">
                  价格
                </Link>
              </li>
*/}

                            {/*<li className={this.props.current === "/news" ? "nav-item active" : "nav-item"}>*/}
                            {/*    <Link className="nav-link" to="/news">*/}
                            {/*        新闻动态*/}
                            {/*    </Link>*/}
                            {/*</li>*/}
                            {/*<li className={this.props.current === "/info" ? "nav-item active" : "nav-item"}>*/}
                            {/*    <Link className="nav-link" to="/info">*/}
                            {/*        付费资讯*/}
                            {/*    </Link>*/}
                            {/*</li>*/}
                            <li className={this.props.current === "/about-us" ? "nav-item active" : "nav-item"}>
                                {/*<a className="nav-link" href="/about-us">关于我们</a>*/}
                                <Link className="nav-link" to="/about-us">
                                    联系我们
                                </Link>
                            </li>

                            {/*<li class="nav-item">*/}
                            {/*  <a class="nav-link" href="#">合作加盟</a>*/}
                            {/*</li>*/}
                        </ul>
                    </div>
                </nav>
            </header>
        )
    }
}

export default Header
